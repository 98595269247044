import { blue, green, red, orange, yellow, gray } from '@ant-design/colors';

export const STATUS_TYPES = {
    'INITIAL': 'Initial',
    'VIEWED': 'Viewed',
    'SUBMITTED': 'Submitted',
    'NEED_RESPONSE': 'Needs Response',
    'COMPLETE': 'Complete'
}

export const STATUS_TYPES_META = {
    'Initial': {
        color: undefined
    },
    'Viewed': {
        color: yellow[0]
    },
    'Submitted': {
        color: orange[1]
    },
    'Needs Response': {
        color: red[1]
    },
    'Complete': {
        color:  blue[1]
    }
}