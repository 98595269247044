import { Descriptions, Skeleton, Tag, Collapse, Table } from 'antd'
import { useEffect, FC, useState } from 'react'
import appAxios from '../app_config/axios'
import ViewBuilder from '../ViewBuilder'
import { ConfigProvider } from 'antd';


const { Panel } = Collapse
interface QuestionnaireDescriptionProps {
    PK?: string | null | undefined
}

interface AppInfo {
    Appname?: string,
    AppProjectName?: string,
    AppDivision?: string,
    AppManagedBy?: string,
    ProdServerList?: string,
    TestServerList?: string,
    DevServerList?: string,
    Migrations? : {[key:string]: string | number | null}[]
}

const QuestionnaireDescription: FC<QuestionnaireDescriptionProps> = ({ PK = '' }) => {

    const [appInfo, setAppInfo] = useState<AppInfo>({})
    const [isLoading, setIsLoading] = useState(true)
    const [columns, setColumns] = useState([])
    let endpoint = process.env.REACT_APP_MIGSERV_API_URL + `/appinfo`

    useEffect(() => {
        if (PK && PK !== '') {
            let app_ci_id = PK.split("|")[0]
            appAxios.get(endpoint,{params: {app_ci_id}})
                .then((result) => {
                    setAppInfo(result.data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log('Error')
                    console.log(error)
                })
        }
    }, [])

    const generateInfo = (app_property_name: string) => {
        if (isLoading) {
            return <Skeleton.Input active />
        } else {
            //@ts-ignore
            return appInfo?.[app_property_name] && appInfo?.[app_property_name] !== '' ? appInfo?.[app_property_name] : '--'
        }

    }

    return (
        <div>
            <ConfigProvider
    theme={{
      components: {
        Descriptions: {
          lineHeight: 0.6,
          fontSize: 13
        },
      },
    }}
  >
            <Collapse defaultActiveKey={['1']}>
                <Panel header={`Application Details: ${appInfo?.['Appname']}`}   key="1">
                    <Descriptions
                        size='small'
                        bordered
                        column={{ xxl: 2, xl:1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    >
                        <Descriptions.Item key="1" label="Application Name">{generateInfo('Appname')}</Descriptions.Item>
                        <Descriptions.Item key="2" label="App Project Name">{generateInfo('AppProjectName')}</Descriptions.Item>
                        <Descriptions.Item key="3" label="App Division">{generateInfo('AppDivision')}</Descriptions.Item>
                        <Descriptions.Item key="4" label="App Managed By">{generateInfo('AppManagedBy')}</Descriptions.Item>
                    </Descriptions>
                    
                    { appInfo?.Migrations && appInfo.Migrations?.length > 0 ?  <ViewBuilder view_config={{
                        data: appInfo?.['Migrations'],
                        hide_view_details: true,
                        initial_state_react_material_table: {
                            columnOrder: ['MigrationDate','MigrationType','Environment', 'Servername'],
                            sorting: [{id: 'MigrationDate', desc: true},{id: 'MigrationType', desc: true}]
                        }}}  /> : <></> }
                </Panel>
            </Collapse>
            </ConfigProvider>


        </div>
    )
}

export default QuestionnaireDescription